const BASE_URL = 'https://api.themoviedb.org/3'
const TOKEN = process.env.REACT_APP_TMDB_TOKEN
const IMAGE_BASE = 'https://image.tmdb.org/t/p'
// Don't have actual air time, presume it's 9pm
const AIR_TIME = '21:00'
// US Eastern
const AIR_TIME_OFFSET = '-04:00'

const zeroFill = number => (`0${number}`).slice(-2)
const getSeasonKey = number => `S${zeroFill(number)}`
const getEpisodeKey = number => `E${zeroFill(number)}`

const fetchHelper = (url, options = {}) => (
  fetch(url, {
    ...options,
    headers: {
      Authorization: `Bearer ${TOKEN}`
    }
  }).then(r => r.json())
)

const formatShow = show => ({
  ...show,
  image_url: `${IMAGE_BASE}/w500/${show.poster_path}`,
  backdrop_url: `${IMAGE_BASE}/w1280/${show.backdrop_path}`,
  thumb_url: `${IMAGE_BASE}/w200/${show.poster_path}`,
})

const formatSeason = season => ({
  ...season,
  key: getSeasonKey(season.season_number)
})

const formatEpisode = ep => ({
  ...ep,
  key: getSeasonKey(ep.season_number) + getEpisodeKey(ep.episode_number),
  airTime: `${ep.air_date}T${AIR_TIME}${AIR_TIME_OFFSET}`
})

export const fetchTrending = async () => {
  const result = await fetchHelper(`${BASE_URL}/trending/tv/week`)
  const shows = result.results.map(formatShow)
  return shows
}

export const fetchShow = async (_, id) => {
  const result = await fetchHelper(`${BASE_URL}/tv/${id}`)

  return {
    ...formatShow(result),
    seasons: result.seasons.filter(s => s.season_number !== 0).map(formatSeason)
  }
}

export const fetchSeason = async (id, seasonNo) => {
  const result = await fetchHelper(`${BASE_URL}/tv/${id}/season/${seasonNo}`)

  return {
    ...formatSeason(result),
    episodes: result.episodes.map(formatEpisode)
  }
}

export const fetchSearch = async query => {
  const response = await fetchHelper(
    `${BASE_URL}/search/tv?query=${encodeURIComponent(query)}`
  )

  return response.results
}
