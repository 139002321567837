import React, { useState } from 'react'
import {
  Button, Box, FormControl, FormLabel, Input,
  useToast
} from '@chakra-ui/core'
import { useForm } from 'react-hook-form'

import { auth } from '../firebase'

import FormError from './FormError'
import Modal from './Modal'

const Login = () => {
  const {
    register, handleSubmit, errors, setError
  } = useForm()
  const [isOpen, setIsOpen] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const toast = useToast()

  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)

  const onSubmit = async data => {
    setIsFetching(true)
    try {
      await auth.signInWithEmailAndPassword(
        data.email,
        data.password
      )

      toast({ title: 'Logged in', status: 'success' })
    } catch (e) {
      setIsFetching(false)
      setError('password', 'validate', e.message)
    }
  }

  const emailValidation = {
    required: 'Required',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: 'Invalid email address'
    }
  }

  const passwordValidation = {
    required: 'Required'
  }

  return (
    <>
      <Button variantColor="blue" onClick={open} ml={3}>
        Login
      </Button>
      <Modal isOpen={isOpen} title="Login" onClose={close}>
        <form onSubmit={handleSubmit(onSubmit)} disabled>
          <fieldset disabled={isFetching}>
            <FormControl isInvalid={!!errors.email}>
              <FormLabel>Email</FormLabel>
              <Input
                name="email"
                placeholder="Email"
                autoFocus
                ref={register(emailValidation)}
              />
              <FormError errors={errors} name="email" />
            </FormControl>

            <FormControl mt={4} isInvalid={!!errors.password}>
              <FormLabel>Last name</FormLabel>
              <Input
                type="password"
                name="password"
                placeholder="Password"
                ref={register(passwordValidation)}
              />
              <FormError errors={errors} name="password" />
            </FormControl>
            <Box pt={6} pb={3} textAlign="right">
              <Button
                variantColor="blue"
                mr={3}
                type="submit"
                isLoading={isFetching}
              >
                Login
              </Button>
              <Button onClick={close}>Cancel</Button>
            </Box>
          </fieldset>
        </form>
      </Modal>
    </>
  )
}

export default Login
