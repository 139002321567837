import React, { useState, useEffect } from 'react'
import Autosuggest from 'react-autosuggest'
import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  Spinner
} from '@chakra-ui/core'
import { navigate } from '@reach/router'
import { useDebounce } from 'use-debounce'

import { fetchSearch } from '../tmdbServices'

const Search = () => {
  const [suggestions, setSuggestions] = useState([])
  const [value, setValue] = useState('')
  const [isFetching, setIsFetching] = useState(false)
  const [debounced] = useDebounce(value, 500)

  const fetchSuggestions = async () => {
    // const result = await fetchSearch(value)

    const result = await fetch(
      `${process.env.REACT_APP_API_BASE}/search?query=${encodeURIComponent(
        value
      )}`
    ).then(r => r.json())

    setSuggestions(result)
    setIsFetching(false)
  }

  useEffect(() => {
    if (!debounced || !debounced.trim()) {
      return
    }

    fetchSuggestions()
  }, [debounced])

  const onSuggestionsFetchRequested = () => {
  }

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
    setIsFetching(false)
  }

  const onChange = (_, { newValue }) => {
    setIsFetching(true)
    setValue(newValue)
  }
  const onSuggestionSelected = (e, { suggestion }) => {
    e.target.blur()
    navigate(`/show/${suggestion.id}`)
  }

  const getSuggestionValue = suggestion => suggestion.name

  const renderSuggestion = (suggestion, { isHighlighted }) => (
    <Box bg={isHighlighted ? '#ccc' : 'white'} p="1">
      {suggestion.name}
    </Box>
  )

  const renderInputComponent = inputProps => (
    <Box w="300px">
      <InputGroup>

        <Input color="black" {...inputProps} />
        {isFetching && (
          <InputRightElement>
            <Spinner color="gray.500" />
          </InputRightElement>
        )}
      </InputGroup>
    </Box>
  )

  const renderSuggestionsContainer = ({ containerProps, children }) => (
    <Box
      bg="white"
      color="#000"
      w="300px"
      pos="absolute"
      {...containerProps}
    >
      {children}
    </Box>
  )

  const inputProps = {
    placeholder: 'Search Shows',
    value,
    onChange
  }

  return (
    <Box pos="relative">
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        renderInputComponent={renderInputComponent}
        renderSuggestionsContainer={renderSuggestionsContainer}
        onSuggestionSelected={onSuggestionSelected}
        focusInputOnSuggestionClick={false}
      />
    </Box>
  )
}

export default Search
