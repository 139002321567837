import React, { lazy } from 'react'
import { Router } from '@reach/router'

import App from './App'

const MyFavorites = lazy(() => import('./pages/MyFavorites'))
const Shows = lazy(() => import('./pages/Shows'))
const Show = lazy(() => import('./pages/Show'))
const Watchlist = lazy(() => import('./pages/Watchlist'))

const Routes = () => (
  <Router primary={false} className="router">
    <App path="/">
      <Shows path="/" />
      <MyFavorites path="favorites" />
      <Show path="show/:id" />
      <Watchlist path="next" />
    </App>
  </Router>
)

export default Routes
