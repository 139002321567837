import React, { Suspense } from 'react'
import './App.css'
import { SWRConfig } from 'swr'
import {
  ThemeProvider, CSSReset, Box, ColorModeProvider
} from '@chakra-ui/core'
import PropTypes from 'prop-types'

import FullScreenSpinner from './components/FullScreenSpinner'
import Header from './components/Header'
import Sidebar from './components/Sidebar'
import { watchFavorites } from './services'
import { watchUser } from './auth'
import AppContext from './Context'
import { theme, globalStyles } from './theme'

const Parent = ({ children }) => (
  <SWRConfig value={{ suspense: true }}>
    <ThemeProvider theme={theme}>
      <ColorModeProvider>
        <CSSReset config={globalStyles} />
        <Suspense fallback={<FullScreenSpinner />}>
          <App>{children}</App>
        </Suspense>
      </ColorModeProvider>
    </ThemeProvider>
  </SWRConfig>
)

Parent.propTypes = {
  children: PropTypes.node.isRequired
}

const App = ({ children }) => {
  const [user, setUser] = React.useState(null)
  const [favorites, setFavorites] = React.useState(null)

  React.useEffect(() => (
    watchUser(setUser)
  ), [])

  React.useEffect(() => {
    if (!user) { return undefined }
    return watchFavorites(user.uid, setFavorites)
  }, [user])

  React.useEffect(() => {
    if (!user) { return }
    const fetchapi = () => {
      user.getIdToken().then(token => {
        console.log('token!', token)
        const options = {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
        fetch(`${process.env.REACT_APP_API_BASE}/hello`, options)
          .then(res => res.json())
          .then(json => console.log('resp', json))
      })
    }
    fetchapi()
  }, [user])

  if (!user || !favorites) {
    return <FullScreenSpinner />
  }


  return (
    <AppContext.Provider value={{ user, favorites }}>
      <Header />
      <Sidebar />
      <Box ml="200px" mt="60px">
        <Suspense fallback={<FullScreenSpinner />}>
          {children}
        </Suspense>
      </Box>
    </AppContext.Provider>
  )
}

App.propTypes = {
  children: PropTypes.node.isRequired
}

export default Parent
