import React from 'react'
import { Flex, Spinner } from '@chakra-ui/core'

const FullScreenSpinner = () => (
  <Flex alignItems="center" justifyContent="center" height="100vh">
    <Spinner size="xl" />
  </Flex>
)

export default FullScreenSpinner
