import React from 'react'
import { Box, Image, PseudoBox } from '@chakra-ui/core'
import { Link as RouterLink } from '@reach/router'

import { theme } from '../theme'

import Link from './Link'

const isActive = ({ isCurrent }) => ({
  style: {
    display: 'block',
    backgroundColor: isCurrent ? theme.colors.gray[700] : 'transparent',
    padding: '6px 0px'

  }
})

const MenuItem = ({ children, to }) => (
  <PseudoBox borderBottom="2px solid #fff" _last={{ borderBottom: 'none' }}>
    <RouterLink getProps={isActive} to={to} display="block">
      {children}
    </RouterLink>
  </PseudoBox>
)

const Menu = () => (
  <Box textAlign="center" fontSize="lg">
    <MenuItem to="/">Trending</MenuItem>
    <MenuItem to="/favorites">Favorites</MenuItem>
    <MenuItem to="/next">Watchlist</MenuItem>
  </Box>
)
const Sidebar = () => (
  <Box
    w="200px"
    bg="gray.500"
    color="#fff"
    position="fixed"
    top="0"
    bottom="0"
    zIndex="10"
    borderRight="1px solid"
    borderColor="gray.600"
  >
    <Box pb="3">
      <Link to="/">
        <Image src="/images/logo_transparent_cropped.png" />
      </Link>
    </Box>
    <Menu />
  </Box>
)

export default Sidebar
