import React, { useContext } from 'react'

import AppContext from '../Context'

import SignUp from './SignUp'
import Login from './Login'
import Logout from './Logout'

const AuthToolbar = () => {
  const { user } = useContext(AppContext)
  const loggedIn = user && !user.isAnonymous
  return (
    <>
      {!loggedIn && (
        <>
          <SignUp />
          <Login />
        </>
      )}
      {loggedIn && (
        <>
          <span>{user.email}</span>
          <Logout />
        </>
      )}
    </>
  )
}

export default AuthToolbar
