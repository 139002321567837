import React from 'react'
import { FormErrorMessage } from '@chakra-ui/core'

const FormError = ({ errors, name }) => {
  if (!errors || !errors[name]) { return null }
  return (
    <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
  )
}

export default FormError
