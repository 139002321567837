import React, { useState } from 'react'
import {
  Button, Box, FormControl, FormLabel, Input,
  useToast
} from '@chakra-ui/core'
import { useForm } from 'react-hook-form'

import { auth } from '../firebase'

import Modal from './Modal'
import FormError from './FormError'

// TODO: try to reuse Login component, or add same spinner
const SignUp = () => {
  const {
    register, handleSubmit, errors, setError
  } = useForm()
  const [isOpen, setIsOpen] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const toast = useToast()

  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)

  const onSubmit = async data => {
    setIsFetching(true)
    try {
      const result = await auth.createUserWithEmailAndPassword(
        data.email,
        data.password
      )
      result.user.sendEmailVerification()

      toast({
        title: 'Account created!',
        status: 'success',
        description: 'You will receive verification email shortly'
      })
    } catch (e) {
      setIsFetching(false)
      setError(
        e.code.includes('email') ? 'email' : 'password',
        'validate',
        e.message
      )
    }
  }

  const emailValidation = {
    required: 'Required',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: 'Invalid email address'
    }
  }

  const passwordValidation = {
    required: 'Required'
  }

  return (
    <>
      <Button variantColor="blue" onClick={open}>
        Sign Up
      </Button>
      <Modal isOpen={isOpen} title="Create new account" onClose={close}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset disabled={isFetching}>
            <FormControl isInvalid={!!errors.email}>
              <FormLabel>Email</FormLabel>
              <Input
                name="email"
                placeholder="Email"
                autoFocus
                ref={register(emailValidation)}
              />
              <FormError errors={errors} name="email" />
            </FormControl>

            <FormControl mt={4} isInvalid={!!errors.password}>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                name="password"
                placeholder="Password"
                ref={register(passwordValidation)}
              />
              <FormError errors={errors} name="password" />
            </FormControl>
            <Box pt={6} pb={3} textAlign="right">
              <Button
                variantColor="blue"
                mr={3}
                type="submit"
                isLoading={isFetching}
              >
                Create
              </Button>
              <Button onClick={close}>Cancel</Button>
            </Box>
          </fieldset>
        </form>
      </Modal>
    </>
  )
}

export default SignUp
