export default {
  apiKey: 'AIzaSyAn-763Fil6WIiFZ_Zc7C7MrJFWjxz6XD4',
  authDomain: 'episode-9c45c.firebaseapp.com',
  databaseURL: 'https://episode-9c45c.firebaseio.com',
  projectId: 'episode-9c45c',
  storageBucket: 'episode-9c45c.appspot.com',
  messagingSenderId: '350525695477',
  appId: '1:350525695477:web:0e1932e8d53f530b5aacbc',
  measurementId: 'G-M1R6V104KL'
}
