import React from 'react'
import { Box, Flex, IconButton } from '@chakra-ui/core'
import { FaBars } from 'react-icons/fa'

import AuthToolbar from './AuthToolbar'
import Search from './Search'

const Header = () => {
  const [show, setShow] = React.useState(false)
  const handleToggle = () => setShow(!show)

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding={0}
      bg="navy.500"
      color="white"
      pos="fixed"
      left="0"
      right="0"
      top="0"
      ml="200px"
      p={2}
      h="60px"
      zIndex="9"
    >
      <IconButton
        display={{ xs: 'block', sm: 'none' }}
        onClick={handleToggle}
        icon={FaBars}
        variant="ghost"
        bg="transparent"
      />

      <Box flexGrow={1}>
        <Search />
      </Box>

      <Box
        display={{ xs: show ? 'block' : 'none', sm: 'block' }}
        mt={{ base: 4, sm: 0 }}
      >
        <AuthToolbar />
      </Box>
    </Flex>
  )
}

export default Header
