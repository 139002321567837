import React, { useState, useEffect, useContext } from 'react'
import { Button } from '@chakra-ui/core'

import { auth } from '../firebase'
import AppContext from '../Context'

const Logout = () => {
  const { user } = useContext(AppContext)
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    // Need to do in effect because logged is done
    // when user.uid changes from watcher
    setIsFetching(false)
  }, [user.uid])

  const handleClick = async () => {
    setIsFetching(true)
    await auth.signOut()
  }

  return (
    <Button
      ml={3}
      onClick={handleClick}
      variantColor="red"
      isLoading={isFetching}
    >
      Logout
    </Button>
  )
}

export default Logout
