import Firebase from 'firebase/app'
import moment from 'moment'

import { db } from './firebase'

const ID_KEY = 'tmdbId'

const favoritesConverter = {
  fromFirestore: snapshot => {
    const data = snapshot.data()
    return {
      ...data,
      id: data[ID_KEY],
      internalId: snapshot.id
    }
  }
}

export const fetchFavorites = async uid => {
  const result = await db.collection('favorites')
    .where('uid', '==', uid).withConverter(favoritesConverter).get()
  return result.docs.map(doc => doc.data())
}

export const fetchAddFavorite = (uid, id) => (
  // TODO: add timestamp
  db.collection('favorites').add({
    watched: [],
    uid,
    [ID_KEY]: id,
  })
)

export const fetchDelFavorite = async id => (
  db.collection('favorites').doc(id).delete()
)

const batchUpdateWatched = async (id, episodeKeys, method) => (
  db.collection('favorites').doc(id).update({
    watched: method(...episodeKeys)
  })
)

export const fetchAddWatched = (id, episodeKeys) => (
  batchUpdateWatched(id, episodeKeys, Firebase.firestore.FieldValue.arrayUnion)
)

export const fetchDelWatched = (id, episodeKeys) => (
  batchUpdateWatched(id, episodeKeys, Firebase.firestore.FieldValue.arrayRemove)
)

export const saveShow = (id, data) => db.collection('shows').doc(id).set({
  ...data,
  updated: Firebase.firestore.FieldValue.serverTimestamp()
})

// TODO extract and reuse
const isReleased = time => moment(time).isBefore()

export const fetchShow = async (_, id) => {
  const result = await db.collection('shows').doc(id.toString()).get()
  if (!result.exists) { return null }

  const showData = result.data()
  console.log('showData', showData)
  return {
    ...showData,
    id,
    getReleasedEpisodes: () => (
      showData.episodes.filter(ep => isReleased(ep.airTime))
    ),
    getUnreleasedEpisodes: () => (
      showData.episodes.filter(ep => !isReleased(ep.airTime))
    )
  }
}

export const watchFavorites = (uid, callback) => (
  db.collection('favorites').where('uid', '==', uid)
    .withConverter(favoritesConverter).onSnapshot(snapshot => {
      callback(snapshot.docs.map(doc => doc.data()))
    })
)
