import React from 'react'
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalContent,
} from '@chakra-ui/core'

const Modal = ({
  title, children, isOpen, onClose
}) => (
  <ChakraModal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>{title}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        {children}
      </ModalBody>
    </ModalContent>
  </ChakraModal>
)

export default Modal
