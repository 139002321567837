import { theme as defaultTheme } from '@chakra-ui/core'
import merge from 'lodash/merge'

export const theme = merge(defaultTheme, {
  colors: {
    red: {
      50: '#F9A6A2',
      100: '#F89794',
      200: '#F68987',
      300: '#F47A79',
      400: '#F26C6C',
      500: '#F05D5E',
      600: '#DB5152',
      700: '#C54647',
      800: '#B03A3B',
      900: '#9A2F2F',
    },

    gray: {
      50: '#AEB4B8',
      100: '#959CA0',
      200: '#7B8388',
      300: '#626A71',
      400: '#495159',
      500: '#303841',
      600: '#2A313B',
      700: '#242A34',
      800: '#1E232E',
      900: '#181D28',
    },

    yellow: {
      50: '#F2D37A',
      100: '#EDC870',
      200: '#E9BD66',
      300: '#E4B35C',
      400: '#E0A851',
      500: '#DB9D47',
      600: '#C6893E',
      700: '#B07635',
      800: '#9B622C',
      900: '#864F24',
    },

    navy: {
      50: '#7CCACB',
      100: '#66B8B9',
      200: '#50A6A8',
      300: '#3B9596',
      400: '#258385',
      500: '#0F7173',
      600: '#0D6567',
      700: '#0B595B',
      800: '#094D4E',
      900: '#084242',
    },

    notQuitewhite: '#E7ECEF'

  }

})

export const globalStyles = thm => ({
  light: {
    color: thm.colors.gray[800],
    bg: thm.colors.notQuitewhite,
    borderColor: thm.colors.gray[200],
    placeholderColor: thm.colors.gray[400],
  },
  dark: {
    color: thm.colors.whiteAlpha[900],
    bg: thm.colors.gray[800],
    borderColor: thm.colors.whiteAlpha[300],
    placeholderColor: thm.colors.whiteAlpha[400],
  },
})
