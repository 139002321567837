import Firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/analytics'

import config from './config'

const fb = Firebase.initializeApp(config)
export const db = fb.firestore()
export const auth = fb.auth()

// db.useEmulator("localhost", 8080)

Firebase.analytics()
